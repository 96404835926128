import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveSlices from "../dataResolvers/resolveSlices";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";
import SalesSheet from "../../pageTemplates/SalesSheet";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicSalesSheets }) => {
  const { prefixed, unPrefixed } = resolveByPrefix(prismicSalesSheets.data, [
    "meta_",
  ]);
  const { meta } = prefixed;
  const { heading, subheading, customer_logo, body } = unPrefixed;

  const resolved = {
    heading,
    subheading,
    customerLogo: resolveImageData({ image: customer_logo }),
    body: resolveSlices(body),
    meta,
  };

  return keysToCamel(resolved);
};

const SalesSheetQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <SalesSheet {...data} location={location} />;
};

export default SalesSheetQuery;

export const query = graphql`
  query ($uid: String!) {
    prismicSalesSheets(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        heading
        subheading
        customer_logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicSalesSheetsBodyParagraph {
            id
            slice_type
            primary {
              heading
              text {
                html
              }
            }
          }
          ... on PrismicSalesSheetsBodyList {
            id
            slice_type
            primary {
              heading
            }
            items {
              text {
                html
              }
            }
          }
          ... on PrismicSalesSheetsBodyTestimonial {
            id
            slice_type
            primary {
              text {
                html
              }
              name
              title
              photo {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicSalesSheetsBodyContact {
            id
            slice_type
            primary {
              heading
              description {
                html
              }
              email
              phone
            }
          }
        }
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;
