import React from "react";
import classNames from "classnames";
import { Layout, SEO, Hero, Slice } from "@components";
import { useDefaultSalesSheetData } from "@queries";

/* eslint react/prop-types: 0 */

const SalesSheet = ({ heading, subheading, customerLogo, meta, body }) => {
  const master = useDefaultSalesSheetData();

  const logos = [master.logo];
  if (customerLogo) {
    logos.push(customerLogo);
  }

  const hero = {
    heading: heading || master.heading,
    subheading,
    screenshot: master.screenshot,
    logos,
  };

  const slices = body.length > 0 ? body : master.body;

  return (
    <Layout className="bg-white text-gray" hideHeader hideFooter>
      <SEO {...meta} />

      <section className="px-6 border-b-1 border-gray-1/4">
        <Hero {...hero} />
      </section>

      <div className="">
        {slices.map((slice, idx) => (
          <section
            key={slice.id}
            className={classNames({
              "pt-10 px-6": true,
              "pb-10": idx === slices.length - 1,
            })}
          >
            <div className="container max-w-md mx-auto">
              <Slice {...slice} />
            </div>
          </section>
        ))}
      </div>
    </Layout>
  );
};

export default SalesSheet;
